.btn {
  display: inline-block;
  border-radius: 30px;
  font-size: 16px;
  line-height: 20px;
  padding: 15px 26px;
  text-align: center;
  min-width: 170px;
  border: none;
  background-color: transparent;
  transition: background-color 0.2s ease;
  cursor: pointer;
  font-family: $font-body;
  text-decoration: none;

  &--100 {
    width: 100%;
  }

  &--primary {
    color: white;
    background-color: $color-pink;

    &:hover {
      background-color: rgba(255, 101, 139, 0.8); // Color pink in 80%;
    }

    &:disabled {
      background-color: rgba(60, 60, 60, 0.2);
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &--secondary {
    color: $color-body;
    border: 1px solid rgba(60, 60, 60, 0.2);

    &:hover {
      background-color: rgba(60, 60, 60, 0.1);
    }

    &.btn--active {
      color: $color-pink;
      border: 1px solid $color-pink;
    }

    &.btn--active-green {
      color: white;
      background-color: $color-turquoise;
      border-color: rgba(60, 60, 60, 0.2);
    }

    &.btn--active-pink {
      color: white;
      background-color: $color-pink;
    }
  }

  &--outline-white {
    color: #fff;
    border: 1px solid rgba(255, 255, 255,1);

    &:hover {
      background-color: rgba(60, 60, 60, 0.1);
    }

    &.btn--active {
      color: $color-pink;
      border: 1px solid $color-pink;
    }

    &.btn--active-green {
      color: white;
      background-color: $color-turquoise;
      border-color: rgba(60, 60, 60, 0.2);
    }

    &.btn--active-pink {
      color: white;
      background-color: $color-pink;
    }
  }

  &--login {
    width: 2.9rem;
    height: 2.9rem;

    padding: 0;
    margin: 0 0.5rem;

    position: relative;
    min-width: unset;
    display: flex;
    justify-content: center;
    border-radius: 1rem;

    font-size: 16px;
    line-height: 20px;
    text-align: center;
    border: none;
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
  }

  &--green {
    color: white;
    background-color: $color-turquoise;

    &:hover {
      background-color: rgba(92, 202, 174, 0.8);
    }
  }

  &--pink {
    color: $color-pink;
    border: 1px solid $color-pink;

    &:hover {
      background-color: rgba(255, 101, 139, 0.1);
    }
  }

  &--grey {
    color: $color-body;
    background-color: rgba(60, 60, 60, 0.05);
  }

  &--white {
    color: white;
    border-color: white;

    &:hover {
      color: white;
      border-color: $color-pink;
      background-color: $color-pink;
    }
  }

  &--large {
    padding: 23px 42px;
    min-width: 200px;
    border-radius: 60px;
  }

  &--small {
    padding: 6px 15px;
    min-width: initial;
    font-size: 16px;
    line-height: 26px;
  }

  &--rectangle {
    border-radius: 5px;
    min-width: 80px;
    padding: 5px 15px;
    font-size: 14px;
  }

  &--with-icon {
    display: flex;
    align-items: center;
    padding: 9px 62px 9px 20px; // Expects 30px icon, needs reduced padds.
    width: 335px;

    img {
      margin-right: 20px;
    }
  }
}
