.slick-list {
  overflow: visible !important;
}

.slick-slide {
  > div {
    margin-right: 10px !important;
  }
}

.slick-no-margins {
  .slick-slide {
    > div {
      margin-right: 0 !important;
    }
  }
}

@media only screen and (min-width: 992px) {
  .slick-list {
    overflow: hidden !important;
  }

  .slick-overflow {
    .slick-list {
      overflow: visible !important;
    }
  }

  .slick-track {
    margin-left: initial !important;
    margin-right: initial !important;
  }

  .slick-slide {
    > div {
      margin-right: 30px !important;
    }
  }
}