.image-gallery-thumbnails-container {
  text-align: left !important;
  margin-top: 20px !important;
}

.image-gallery-thumbnail-image {
  border-radius: 5px !important;
}

.image-gallery-svg {
  width: 30px !important;
  height: 50px !important;
}

.image-gallery-fullscreen-button {
  height: 50px !important;
}

@include media-tablet {
  .image-gallery-svg {
    height: 120px !important;
  }

  .image-gallery-fullscreen-button {
    height: 80px !important;

    svg {
      height: 50px !important;
    }
  }
}