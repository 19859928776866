.search-page {
  & > div {
    margin: 1rem auto 2rem;
  }
  &--title {
    font-weight: bold;
  }
  &--pages {
    &-title {
      margin: 1rem auto 1rem;
    }
    &-content {
      overflow-y: hidden;
      text-overflow: ellipsis;
      margin: 1rem initial;
      & * h4 {
        @extend p;
        font-weight: 500;
        margin-top: 0.4rem;
        font-size: 0.85rem;
      }
      &-text {
        display: block;
        margin: 0.3rem initial;
        & > p,
        li {
          font-size: 0.85rem;
        }
      }
      &-button {
        cursor: pointer;
        font-size: 0.85rem;
        margin-top: 1.5rem;
        color: $color-pink;
      }
    }
  }
  &--button {
    margin-top: 1rem;
  }
}
