.react-datepicker {
  &__input-container {
    input {
      border: none !important;
      background-color: transparent !important;
      padding: 0 !important;
      font-family: $font-body !important;
      font-size: 16px !important;
      line-height: 26px !important;
      margin-bottom: 0 !important;
    }
  }
}

.pink-datepicker {
  .react-datepicker {
    &__input-container {
      input {
        color: $color-pink;
        text-align: right;
        padding-right: 37px !important;
        font-size: 16px !important;
        line-height: 26px !important;
      }
    }
  }
}

.small-datepicker {
  .react-datepicker {
    &__input-container {
      input {
        text-align: right;
        font-size: 14px !important;
        line-height: 26px !important;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .react-datepicker {
    &__input-container {
      input {
        font-size: 18px !important;
        line-height: 27px !important;
      }
    }
  }

  .small-datepicker {
    //max-width: 115px;

    .react-datepicker {
      &__input-container {
        input {
          margin-top: 0;
          text-align: left;
        }
      }
    }
  }
}
