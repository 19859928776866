html,
body {
  font-size: $body-font-size-mobile;
  line-height: $body-line-height-mobile;
  letter-spacing: $body-letter-spacing-mobile;
  font-weight: $body-font-weight-mobile;
  color: $color-body;
  font-family: $font-body;
  scroll-behavior: smooth;
}

:target:before {
  content: '';
  display: block;
  height: 120px;
  margin: -120px 0 0;
}

.event-page {
  :target:before {
    content: '';
    display: block;
    height: 200px;
    margin: -200px 0 0;
  }
}

.gradient-text {
  background-color: $color-pink;
  background: -webkit-linear-gradient(0deg, $color-pink, $color-orange);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.error {
  font-size: 14px;
  line-height: 21px;
  font-weight: 300;
  color: #d13725;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font-heading;
}

h1,
.h1 {
  font-size: $h1-font-size-mobile;
  line-height: $h1-line-height-mobile;
  letter-spacing: $h1-letter-spacing-mobile;
  font-weight: $h1-font-weight-mobile;
  margin-bottom: 20px;
}

h2,
.h2 {
  font-size: $h2-font-size-mobile;
  line-height: $h2-line-height-mobile;
  letter-spacing: $h2-letter-spacing-mobile;
  font-weight: $h2-font-weight-mobile;
  margin-bottom: 10px;
}

h3,
.h3 {
  font-size: $h3-font-size-mobile;
  line-height: $h3-line-height-mobile;
  letter-spacing: $h3-letter-spacing-mobile;
  font-weight: $h3-font-weight-mobile;
  margin-bottom: 10px;
}

h4,
.h4 {
  font-size: $h4-font-size-mobile;
  line-height: $h4-line-height-mobile;
  letter-spacing: $h4-letter-spacing-mobile;
  font-weight: $h4-font-weight-mobile;
  margin-bottom: 10px;
}

h5,
.h5 {
  font-size: $h5-font-size-mobile;
  line-height: $h5-line-height-mobile;
  letter-spacing: $h5-letter-spacing-mobile;
  font-weight: $h5-font-weight-mobile;
  margin-bottom: 10px;
}

h6,
.h6 {
  font-size: $h6-font-size-mobile;
  line-height: $h6-line-height-mobile;
  letter-spacing: $h6-letter-spacing-mobile;
  font-weight: $h6-font-weight-mobile;
  margin-bottom: 10px;
}

.small {
  font-size: $body-small-font-size-mobile;
  line-height: $body-small-line-height-mobile;
  letter-spacing: $body-small-letter-spacing-mobile;
  font-weight: $body-small-font-weight-mobile;
}

.extra-small {
  font-size: $body-extra-small-font-size-mobile;
  line-height: $body-extra-small-line-height-mobile;
  letter-spacing: $body-extra-small-letter-spacing-mobile;
  font-weight: $body-extra-small-font-weight-mobile;
}

.text-center {
  text-align: center;
}
.text-break-spaces {
  white-space: break-spaces;
}

.pink-text {
  color: $color-pink;
}

@media only screen and (min-width: 992px) {
  html,
  body {
    font-size: $body-font-size;
    line-height: $body-line-height;
    letter-spacing: $body-letter-spacing;
    font-weight: $body-font-weight;
  }

  h1,
  .h1 {
    font-size: $h1-font-size;
    line-height: $h1-line-height;
    letter-spacing: $h1-letter-spacing;
    font-weight: $h1-font-weight;
    margin-bottom: 30px;
  }

  h2,
  .h2 {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    letter-spacing: $h2-letter-spacing;
    font-weight: $h2-font-weight;
    margin-bottom: 30px;
  }

  h3,
  .h3 {
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    letter-spacing: $h3-letter-spacing;
    font-weight: $h3-font-weight;
    margin-bottom: 30px;
  }

  h4,
  .h4 {
    font-size: $h4-font-size;
    line-height: $h4-line-height;
    letter-spacing: $h4-letter-spacing;
    font-weight: $h4-font-weight;
    margin-bottom: 30px;
  }

  h5,
  .h5 {
    font-size: $h5-font-size;
    line-height: $h5-line-height;
    letter-spacing: $h5-letter-spacing;
    font-weight: $h5-font-weight;
  }

  h6,
  .h6 {
    font-size: $h6-font-size;
    line-height: $h6-line-height;
    letter-spacing: $h6-letter-spacing;
    font-weight: $h6-font-weight;
  }

  .small {
    font-size: $body-small-font-size;
    line-height: $body-small-line-height;
    letter-spacing: $body-small-letter-spacing;
    font-weight: $body-small-font-weight;
  }
}
