
        @import 'styles/variables';
        @import 'styles/media';
      
@import 'variables';
@import 'reset-css';
@import 'media';

// Common global styles.
@import 'typography';
@import 'lists';
@import 'links';
@import 'buttons';
@import 'forms';
@import 'container';
@import 'video-embeds';

// Page styles
@import 'search-page';

// Slider.
@import 'libraries/slick';
@import 'slick-overrides';

// Datepicker.
@import 'react-datepicker/dist/react-datepicker.css';
@import 'datepicker-overrides';

// Image gallery.
@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import 'imagegallery-overrides';

.flex {
  &.wrap {
    flex-wrap: wrap;
  }
  &.row {
    flex-direction: row;
  }
  &.stretch {
    justify-content: space-between;
    align-items: baseline;
    align-content: stretch;
  }
  &.center {
    justify-content: center;
    align-items: center;
  }

  &.end {
    justify-content: end;
  }

  &.start {
    text-align: initial;
  }
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
}
.w-100 {
  width: 100%;
}

:fullscreen {
  background-color: black;
}

.fullscreen .image-gallery-image {
  height: 79vh !important;
}
.fullscreen .image-gallery-thumbnail-image {
  max-height: 18vh;
}
.fullscreen .image-gallery-thumbnails-container {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.me {
  @for $var from 0 through 5 {
    &-#{$var} {
      margin-right: #{$var * 0.5}rem !important;
    }
  }
}
.ms {
  @for $var from 0 through 5 {
    &-#{$var} {
      margin-left: #{$var * 0.5}rem !important;
    }
  }
}
.ps {
  @for $var from 0 through 5 {
    &-#{$var} {
      padding-left: #{$var * 0.5}rem !important;
    }
  }
}

.text-500 {
  font-weight: 500;
}
.mb-2 {
  margin-bottom: 0.5rem!important
}
.mb-3 {
  margin-bottom: 0.75rem!important
}

.my-3 {
  margin-bottom: 0.75rem!important;
  margin-top: 0.75rem!important
}

.my-4 {
  margin-bottom: 1.5rem!important;
  margin-top: 1.5rem!important
}

.mb-4 {
  margin-bottom: 1.5rem!important;
}

.mt-n3 {
  margin-top: -0.75rem!important
}

@media screen and (max-width: 992px) {
  .mobile-center {
    justify-content: center!important;
    text-align: center!important;
    }

    .w-100-mobile {
      width: 100%!important
    }
  }

  .row-columns-allowed {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%
  }

.col-8 {
  max-width: 66.6666666%;
  width: 66.6666666%
}

.col-4 {
  max-width: 33.333333%;
  width: 33.333333%
}

@media screen and (max-width: 768px) {
  .row-columns-allowed {
    flex-direction: column;
  }
  .col-8 {
    max-width: 100%;
    width: 100%;
    display: block;
  }
  .col-4 {
    max-width: 100%;
    width: 100%;
    display: block;
  }
  .sm-w-100-mobile {
    width: 100%!important
  }
}