input:not([type="checkbox"]):not([type="submit"]),
textarea {
  display: block;
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  line-height: 20px;
  padding: 11px 22px;
  margin-bottom: 11px;
  border: 1px solid #828282;
  border-radius: 4px;
  font-family: $font-body;

  &::placeholder {
    color: $color-body;
  }

  &:focus {
    outline: none;
    border: 1px solid #2D2D2D;
  }
}

textarea {
  resize: vertical;
}

label:not(.no-style) {
  @extend .extra-small;
  margin-bottom: 5px;
}

input[type="submit"] {
  @extend .btn;
  @extend .btn--primary;
}

.select-wrap {
  position: relative;

  &--underline {
    text-decoration: underline;
    padding-left: 20px;

    select {
      margin-right: 20px;
      right: 0;
    }
  }

  &--small {
    &::after {
      top: 0.5rem !important;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 11px;
    right: 0;
    display: block;
    background-image: url('/img/arrow-down.svg');
    background-size: 100% 100%;
    width: 10.16px;
    height: 6.63px;
    z-index: 0;
  }

  select {
    position: relative;
    z-index: 1;
    min-width: 64px;
  }

  &--pink {
    &::after {
      background-image: url('/img/arrow-down-pink-profile.svg');
      width: 20px;
      height: 20px;
      top: 4px;
      right: 8px;
    }
  }

}

select {
  display: block;
  appearance: none;
  border: none;
  font-size: 16px;
  line-height: 26px;
  background-color: transparent;
  width: 100%;
  color: #232323;
  font-family: $font-body;
}

.success-message {
  position: absolute;
}

.select-secondary__single-value {
  color: $color-pink !important;
  text-decoration: underline !important;
}

.select-tertiary__single-value {
  color: $color-pink !important;
  text-decoration: none !important;
}

@media only screen and (min-width: 992px) {
  select {
    font-size: 18px;
    line-height: 27px;
  }
}
