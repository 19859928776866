ul,
ol {
  li {
    @extend .small;
  }
}

ul:not(.no-style) {
  list-style: none;

  li::before {
    content: "\2022";
    color: $color-pink;
    padding-left: 1rem;
    margin-left: -1rem;
    padding-right: 0.5rem;
  }
}

ol {
  list-style: decimal;
  padding-left: 1rem;
}